<template>
  <div>
    <v-container>
      <h2>Contato</h2>
    </v-container>
  </div>
</template>

<script>
import Constants from "@/constants/constants";
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters([
      "stateLogin",
    ]),
  },
  data() {
    return {
      constants: Constants,
      vueAppApiResources: process.env.VUE_APP_API_RESOURCES,
    };
  },
  methods: {},
};
</script>
